@media(max-width:1300px) {
    .join_room.space_join {
        margin-top: 44px;
    }
}

@media(max-width:1350px) {
    .package_data {
        width: 436px;
    }
}

@media(max-width:1199px) {
    .new_data_p {
        display: none;
    }

    .inner_box.box {
        display: none;
    }

    .room {
        display: none;
    }

    .button_wrapper_content {
        display: none;
    }

    .settings {
        display: none;
    }

    .inner_box.view_only {
        display: none;
    }

    .data_new.create-room-new.data_i {
        display: none;
    }

    .room.lobby {
        display: none;
    }

    .line_clock {
        display: none;
    }

    .ant-modal {
        display: none;
    }

    .top_header {
        display: none;
    }

    .join_room.space_join {
        display: none;
    }

    .inner_box {
        display: none;
    }

    .faq_page {
        display: none;
    }

    .package_data {
        display: none;
    }

    .gameScreen {
        display: none;
    }
}