.chat-main-div {
    height: 100%;
    align-items: center;
    width: 100%;
    z-index: 9999;
    position: absolute;
    background: #00000099;
}

.chat-box-vertical-ui {
    /* height: calc(85vh - 258px);
    overflow: auto;
    padding: 18px 0px;
    display: grid;
    align-items: flex-end; */
    /* height: calc(85vh - 255px); */
    overflow: auto;
    /* padding: 18px 0px; */
    display: flex;
    /* justify-content: flex-end; */
    flex-direction: column;
    padding-bottom: 41px;
    padding-top: 20px px;
    /* height: 100%; */
}

::-webkit-scrollbar {
    /* display: none; */
}

.chat-main-outer-div {
    background-color: rgb(33, 33, 33);
    height: 492.1px;
    width: 421.8px;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-box-div {
    margin-top: 8px;
    height: 75%;
    width: 88%;
    border-radius: 10px 10px 25px 25px;
    border: 2px solid rgb(0, 132, 118);
    background-color: rgb(47, 47, 47);
    overflow: auto;
}

.chat-box-list-item-div {
    width: 70%;
    background-color: rgb(39, 38, 38);
    border-radius: 15px;
    justify-content: center;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    word-break: break-word;
}

.chat-box-profile-and-time-view {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 68%;
}

.chat-box-profile-icon-style {
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-left: 5px;
    border-color: rgb(0, 132, 118);
    border-width: 1px;
    margin-top: -1px;
}

.chat-input-toolbar-main-div {
    height: 50px;
    width: 87.5%;
    background-color: rgb(0, 132, 118);
    position: absolute;
    bottom: 64px;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    left: 26px;
}

.chat-input-toolbar-inner-div {
    display: flex;
    height: 35px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.chat-input-style {
    background-color: rgb(47, 47, 47);
    width: 91%;
    height: 100%;
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    margin-left: 12px;
    color: rgb(255, 255, 255);
    border: 0px solid;
}

.chat-box-close-icon-div {
    left: -13px;
    top: -10px;
    flex-direction: row;
    align-items: center;
    position: absolute;
    cursor: pointer;
}

.chat-box-header-div {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
    display: flex;
}

.chat-box-close-icon-view {
    width: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-outer-div {
    display: flex;
    height: 40px;
    width: 98%;
    background-color: rgb(0, 132, 118);
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin-top: 5px;
    flex-direction: row;
    padding: 3px
}

.dropdown-inner-div {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(43, 43, 43);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
}

/* ///// ANDT CSS Changes */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgb(47, 47, 47);
    border: none;
}

.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 7%;
    right: -15px;
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
    background: rgb(0, 132, 118);
    height: 40px;
    width: 63px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    justify-content: center;
}

.ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: #fff;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* border-color: #40a9ff; */
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
    border-right-width: 1px;
    outline: 0;
}

.ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", ;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 9999;
    box-sizing: border-box;
    padding: 3px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    /* border-radius: 16px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    background-color: rgb(47, 47, 47);
    border: 1px solid;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e6f7ff;
    color: #FFF;
    background: rgb(47, 47, 47);
    border-bottom-width: 1px;
    border-style: inset;
    border-top-width: inherit;
    border-right-width: inherit;
    border-left-width: inherit;
    border-color: rgb(0, 132, 118);
}



.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: unset;
}

.ant-select-selection-item {
    flex: 1 1;
    overflow: hidden;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 33px;
    transition: all 0.3s;
}

.data-with-border.ant-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 7px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
    color: #fff;
    border-style: solid;
    border-top-width: inherit;
    border-right-width: inherit;
    border-left-width: inherit;
    /* border-bottom-width: inherit; */
    border-bottom-width: 1px;
    border-color: rgb(0, 132, 118);
}

.data-without-border.ant-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
    color: #fff;
    border-style: ridge;
    border-top-width: inherit;
    border-right-width: inherit;
    border-left-width: inherit;
    border-bottom-width: inherit;
    /* border-bottom-width: 1px; */
    border-color: rgb(0, 132, 118);
}




@media(max-width:1300px) {
    .chat-main-outer-div {
        background-color: rgb(33, 33, 33);
        height: 492.1px;
        width: 292.8px;
        border-radius: 10px;
        position: absolute;
        bottom: 20px;
        right: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .chat-input-toolbar-main-div {
        height: 50px;
        width: 87%;
        background-color: rgb(0, 132, 118);
        position: absolute;
        bottom: 64px;
        justify-content: center;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        left: 18.9px;
    }
}