@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


.body {
    font-family: 'Poppins', sans-serif;
}

.background_image {
    background-image: url(../images/bg.png);
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;


}

.background_image.Winner {

    background-image: url(../images/you-win.png);
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.background_image.loser {
    background-image: url(../images/you-lose.png);

}

.formcontrol {
    border-width: 2px;
    /* border-color: rgb(51, 194, 173); */
    border-radius: 10px;
    padding: 19px 12px;
    width: 100%;
    border: 1px solid rgb(51, 194, 173);
    -webkit-box-shadow: 0px 0px 4px rgb(51 194 173);
    -moz-box-shadow: 0px 0px 4px rgb(51 194 173);
    box-shadow: 0px 0px 4px rgb(51 194 173);
    background-color: #303030;
    font-size: 17px;
    color: #efeded;
    font-weight: 300;



}

.formcontrol::placeholder {
    color: #efeded;

}

/* .form-group {
    display: flex;
    flex-direction: column;
} */
.background_image .inner_box {
    text-align: center;
    margin: auto;
    width: 53%;
    margin: 0 auto;
    padding: 85px 0;
}

.form-group label {
    font-size: 17px;
    color: #ffffff;
    width: 100%;
    display: block;
    text-align: left;
    margin-bottom: 6px;

}

.heading_text h2 {
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    text-shadow: 0px 11px 25.92px rgba(0, 0, 0, 0.14);
    font-size: 20px;
}

.forgot_password p {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;

}

.data_new img {
    width: 243px;
}

.data_new img {
    margin-bottom: 18px;
    cursor: pointer;

}

.inner_box .form-group {
    margin-bottom: 17px;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.button_Wrapper img {
    width: 250px;
}

.button_Wrapper {
    position: relative;
}

.button_Wrapper span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.button_Wrapper span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

.room {
    width: 53%;
    border: 3px solid #2e8f81;
    margin: auto;
    padding: 25px 31px;
    border-radius: 11px;
    border-width: 2px;
    border-color: rgb(49, 163, 147);
    border-radius: 45px;
    /* background-color: rgb(57, 182, 164); */
    box-shadow: inset -2.257px 12.803px 88.29px 20.71px rgb(39 40 40 / 39%);
    background: linear-gradient(-109deg, rgba(39, 40, 40, 0.99608) 0%, rgb(46, 47, 47) 100%);
}

.first_join {
    display: flex;
}

.hostname h4,
.hostname p {
    margin: 0;
    padding: 0;
}

.hostname h4 {
    color: #fff;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}

.hostname h5 {
    color: #fff;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}

.hostname {
    margin-left: 6px;
    word-break: break-all;
    width: 60%;
}

.game1 {
    flex-grow: 1;
    text-align: right;
    color: #fff;
    font-size: 14px;
    font-weight: 500;

}

.button_game img {
    width: 88px;
}

.button_game {
    text-align: right;
}

.inner_room {
    border-radius: 15px;
    padding: 27px 22px;
    background-color: #272727;
    cursor: pointer;
}

.hostname p {
    color: #fff;
    font-weight: 700;
    font-size: 17px;
}

.inner_room:not(last-child) {
    margin-bottom: 18px;
}

.children_play {
    margin-bottom: 46px;
}

.inner_box.box {
    padding-bottom: 0;
}

.button_Wrapper {
    margin-bottom: 20px;
}

h3.rooms {
    color: #2e8f81;
    font-size: 24px;
    margin-bottom: 31px;

}

.data_new.change_password {
    margin-top: 98px;
}

.data_new.text_t {
    margin-top: 40px;
}

span.green {
    background-color: rgb(46, 143, 129);
    width: 12px;
    height: 12px;
    width: 12px;
    display: inline-block;
    border-radius: 100%;
}

.first_join.home {
    align-items: center;
}

span.green.red {
    background-color: rgb(201, 7, 21);
}

.room.lobby .first_join {
    display: flex;
    align-items: center;
}

.room.lobby .inner_room {
    border-radius: 15px;
    padding: 11px 22px;
}

.india_code {
    display: flex;
    justify-content: center;
}

.room-code h2 {
    color: #ffffff;
    font-size: 20px;
}

img.left_code {
    width: 26%;
}

.room-code {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.clock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;

}

.clock span {
    color: #fff;
    font-size: 29px;
}

.clock img {
    width: 6%;
}

.wait p {
    font-size: 23px;
    color: #2e8f81;
    margin: 9px 0 31px;
    font-weight: 700;

}

.Life_wins p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;

}

.Life_wins {
    background-color: #212020;
    text-align: left;
}

.Life_wins p {
    margin: 0;
    margin-bottom: 9px;
}

.wins_ends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 19px;

}

.Life_wins {
    background-color: #212020;
    text-align: left;
    padding: 12px 22px;
    border-radius: 12px;
}

.room.lobby.leaderboard .inner_room {
    padding: 19px 22px;


}

.background_image.image {
    padding: 64px 0;
}

.settings_button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(http://192.168.3.183:3000/static/media/shape.fbed90b….png); */
    background-repeat: no-repeat;
    padding: 0 30px;
    background-size: cover;
    margin: auto;
    position: relative;
    justify-content: space-between;
    height: 90px;
    min-height: 90px;
    width: 638px;

    margin-bottom: 25px;



}

.settings_button h4 {
    color: #fff;
    font-size: 20px;
    margin: 0;

}

.settings_button img {
    width: 89px;
    position: absolute;
    right: 12px;
    top: -5px;
}

.settings_button {
    background-image: url(../images/shape.png);
}

.settings_button:nth-child(1) img {
    width: 53px;
    right: 24px;
    top: 12px;
}

.settings_button:nth-child(2) img {
    width: 64px;
    right: 24px;
    top: 14px;
}

.settings_button:nth-child(3) img {
    width: 67px;
    right: 24px;
    top: 12px;
}

.settings_button:nth-child(4) img {
    width: 70px;
    right: 24px;
    top: 11px;
}

.settings_button:nth-child(5) img {
    width: 44px;
    right: 24px;
    top: 11px;
}

.settings_button:nth-child(6) img {
    width: 74px;
    right: 44px;
    top: 19px;
    right: 24px;
}

.settings_button:nth-child(7) img {
    width: 68px;
    right: 24px;
    top: 12px;
}

.settings_button:nth-child(8) img {
    width: 100px;
    right: 24px;
    top: 21px;
}

.settings_button:nth-child(10) img {
    width: 70px;
    right: 24px;
    top: 14px;
}

.settings_button:nth-child(11) img {
    width: 66px;
    right: 19px;
    top: 14px;
}

.settings_button:nth-child(12) img {
    width: 66px;
    right: 19px;
    top: 14px;
}

.settings_button:nth-child(13) img {

    width: 80px;
    right: 19px;
    top: 11px;

}

.settings_button:nth-child(14) img {
    width: 70px;
    right: 30px;
    top: 15px;
    right: 20px;


}

.settings_button:nth-child(15) img {
    width: 63px;
    position: absolute;
    right: 20px;
    top: 13px;
}

.settings_button:nth-child(16) img {
    width: 58px;
    position: absolute;
    right: 20px;
    top: 10px;
}

.settings_button:nth-child(17) img {
    width: 66px;
    position: absolute;
    right: 20px;
    top: 15px;
}

.img_y img {
    width: 90px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
}

.img_y h2 {
    font-size: 27px;
    text-align: center;
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
    margin-top: -14px;

}

.ant-modal-content {
    background-image: url(../images/pop.png);
    background-color: transparent !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0px 0;

}

.ant-modal-header {
    background: transparent !important;
}

button.ant-modal-close {
    background-image: url(../images/cross.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -66px;
    position: relative;

}

span.anticon.anticon-close.ant-modal-close-icon {
    display: none;
}

.main_pop {
    padding: 0px 73px;
}

img.send_new {
    width: 42%;
    text-align: center;
    margin: auto;
    display: flex;
}

.ant-modal-footer button {
    background: transparent;
    border: 0;

}

.ant-modal-footer {
    border-top: 0px solid #f0f0f0 !important;
    padding: 10px 16px 32px !important;


}

.form-group.success_d p {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.ant-modal-footer img {
    cursor: pointer;
}

.main_pop.image_pop img.send_new {
    width: 100%;

}

.ant-modal-body p {
    /* padding: 54px 4px 0; */
    padding: 0px 35px 0;
}

.main_pop.image_pop img {
    padding: 8px 0;
}

.create-room_img {
    background-image: url(../images/popscreen.png);
    height: 476px;
    margin: auto;
    background-position: center;
    background-position-x: center;
    background-position-y: center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.Auto.text span {
    color: #fff;
}

button.button_switch.auto {
    background-color: #000000;
    padding: 5px 11px;
    font-size: 17px;
    font-weight: 600;
}

button.button_switch {
    background-color: transparent;
    padding: 5px 11px;
    font-size: 17px;
    font-weight: 600;
    border: 0;
    width: 85px;

}

button.button_single {
    background-color: transparent;
    padding: 5px 11px;
    font-size: 17px;
    font-weight: 600;
    border: 0;
    width: 100px;

}

button.button_switch.one {
    background-image: url(../images/button-space.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 96%;
    cursor: pointer;
}

span.button-switch {
    background-color: #000;
    padding: 0px 0;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
}

.Auto.text span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.Auto.text {
    margin-bottom: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 30%;

}

input.form-control.control_new {
    background-color: black;
    border: 0;
    width: 38px;
}

button.add_button {
    background-color: transparent;
    border: 0;
    padding: 0;
}

button.add_button img {
    width: 39px;
    cursor: pointer;
}

input.form-control.control_new {
    background-color: black;
    border: 0;
    width: 62px;
    border-radius: 8px;
    padding: 5px 0;
    margin: 0px 11px;
    text-align: center;

}

.Auto.text.lat-child_d {
    margin-bottom: 0;
}

.data_new.create-room-new {
    margin-top: 58px;
    margin-top: 22px;
    margin-bottom: 43px;
    display: inline-block;
    /* margin: auto; */
    width: 100%;
}

.new_data_p {
    padding-top: 40px;
}

.package_data .big_img {
    width: 100%;
}

.package_data {
    width: 32%;
    margin: auto;
    position: relative;
    width: 432px;
}

.button_wrapper_content {
    text-align: center;
}

.join_room {

    width: 61%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background-image: url(../images/popscreen.png);
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 16px;


}

.middle_spaces {

    padding: 75px 0;

}

.img_y.join_room_rooms h2 {
    margin-bottom: 52px;
}

.main_pop.data_one {
    padding: 0;
    width: 50%;
}

.main_pop.data_one img.send_new {
    width: 52%;
    text-align: center;
    margin: auto;
    display: flex;
    margin-top: 78px;
}

.data_new.create-room-new.data_i {
    text-align: center;
}

button.button_switch.items {
    padding: 5px 13px;
}

.line_clock {
    text-align: center;
}

.form-group.success_d.leave-pop p {
    font-size: 27px;
}

.top_header {
    background-color: rgba(56, 56, 56, 0.302);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 34px;

}

.top_header span {
    color: #fff;
    font-size: 27px;
    font-weight: 600;
}

img.back_img {
    width: 50px;
    cursor: pointer;
}

input:disabled {
    color: #6d6d6d;
    font-size: 18px;
    font-weight: 400;

}

h3.image {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
}

.forgot_password {
    text-decoration: underline;
    color: #fff;
    text-decoration-color: #fff;
    display: inline-block;

}

.data_new.top-space_one {
    margin-top: 77px;
}

.button_game img:first-child {
    margin-right: 13px;
}

span.label_mode {
    width: 199px;
}

.join_room.space_join {
    margin-top: 88px;
}

.inner_box.box.inner_text {
    padding-top: 45px;
}

.main_pop.data_one.respo {
    padding: 0;
    max-width: 100%;
    width: 380px;
}

.background_image.fixed {
    background-attachment: fixed;
}

.settings {
    margin-top: 36px;
}

.settings_button a {
    display: inline-block;
    width: 100%;
    line-height: 65px;
}

.room.lobby.mt_12 {
    margin-top: 61px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 77px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.slide_data input:checked+.slider {
    background: -moz-linear-gradient(-109deg, #2E8F81 0%, #2E8F81 100%);
    background: -webkit-linear-gradient(-109deg, #2E8F81 0%, #2E8F81 100%);
    background: linear-gradient(-109deg, #2E8F81 0%, #2E8F81 100%);
    box-shadow: inset -0.174px 0.985px 7.2px 1.8px rgba(36, 36, 37, 0.6);

}



.slide_data input:checked+.slider:before {
    -webkit-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.faq_page {
    width: 70%;
    margin: auto;
    margin: 12px;
    margin: 64px auto;
    border: 3px solid #2e8f81;
    padding: 23px;
    border-radius: 26px;
}

.ant-collapse-header {
    background-color: #272727;
    color: #fff;
}

.ant-collapse {
    border: 0 !important;
}

.ant-collapse-header {
    font-size: 20px;
    color: #fff !important;
}

.faq_page .ant-collapse-content {
    color: #fff;
    background-color: #272727;
    border-top: 1px solid #d9d9d9;
}

.faq_page .ant-collapse {
    background-color: #272727;

}

.img_y.alert_icons img {
    width: 121px;
}

.data_new.game_lobbies {
    margin-bottom: 56px;
    display: inline-block;
}

.purchase img {
    width: 67%;
    margin-top: 12px;
}

.purchase {
    position: absolute;
    bottom: 62px;
    left: 25%;
}

.purchase.button img {
    cursor: pointer;
}

img.icons_u {
    position: relative;
    top: -40px;
}

.price {
    position: absolute;
    top: 50%;
    color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
}

.price p {
    margin: 0;
    font-size: 30px;
    margin-top: 13px;
}

.form-group.success_d.sucessss p {
    padding: 0;
    font-size: 19px;
}

.view_only .formcontrol {

    border: 1px solid #4e4f4f;
    box-shadow: 0px 0px 4px #4e4f4f;
    background-color: #303030;

}

.scroll_div {
    height: 500px;
    overflow: auto;
}

.scroll_div::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.scroll_div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.scroll_div::-webkit-scrollbar-thumb {
    background: #2e8f81;
    border-radius: 10px;
}

h3.rooms.pb-2 {
    margin-bottom: 0;
}

.chat-wrapper span.text_data {
    background-color: #272727;
    width: 219px;
    display: inline-block;
    padding: 17px 0;
}

.chat-wrapper span.text_data {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-left: auto;
    border-radius: 18px;
    text-align: right;
    padding: 10px 17px;
    margin-bottom: 7px;



}

.chat_new {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.chat-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

p.time_chat {
    color: #757575;
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    display: flex;
    padding-left: 26px;

}

.chat-wrapper.left span.text_data {

    margin-right: auto;
    margin-left: unset;
    text-align: left;


}

.chat-wrapper.left .chat_new {
    display: flex;
    justify-content: flex-start;
}

.inner_box.chat-box {
    border: 3px solid #2e8f81;
    border-radius: 24px;
    padding: 0;
    margin-top: 58px;
    box-shadow: inset -2.257px 12.803px 88.29px 20.71px rgb(39 40 40 / 39%);
    background-color: #1e1e1e;

}

.footer-b {
    background-color: #2e8f81;
    padding: 3px 0;
    border-radius: 0px 0px 15px 15px;
    align-items: center;
    display: flex;

}


.footer-b input.form-control {
    background-color: #272727;
    color: #fff;
    font-size: 18px;
    width: 87%;
    padding: 11px 14px;
    border: 0;
    margin: 15px 8px;
    border-radius: 12px;
}

.winner h3 {
    font-size: 44px;
    font-weight: 600;
    color: #fff;
}

.footer-b img {
    width: 74px;
}

.scroll_div.contacts {

    min-height: calc(100vh - 290px);
    overflow: auto;
    padding: 19px 20px;
    margin-right: 13px;
    height: 100%;


}

.winner p {
    color: #fff;
    font-size: 20px;
    /* font-weight: 500; */
}

.winner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
}

/* .rooms{
     cursor: pointer;
    } */
.spectarule .scroll_div {
    height: 293px;
    overflow: auto;
    padding: 0px 0px;

}

.main_pop.spectarule {
    padding: 0px 0px;

}

.spectarule .inner_room {
    border-radius: 15px;
    padding: 11px 22px;
    display: flex;
    align-items: center;
}

.main_pop.spectarule span {
    font-size: 18px;
    color: #fff;
    margin-left: 11px;
    line-break: anywhere;
    width: 78%;
}

.faq_inner {
    padding: 17px 16px;
    background-color: #272727;
    color: #2e8f81;
    font-size: 16px;
    font-weight: 800;
    word-break: break-all;
}

.faq_page p {
    font-size: 15px;
    color: #fff;
    background-color: #2f2f2fee;
    /* height: 96px; */
    border-top: 1px solid #fff;
    padding: 15px 17px;
    margin: 0;

}

.img_y.passwoerd_modal {
    position: relative;
    top: -33px;
}

.main_pop.spaceing_small {
    padding: 0px 17px;
}

.chat-wrapper .time_chat {
    padding-right: 25px;
}

.main_pop.spaceing_main {
    padding: 0px 42px;
}

.game_join {
    cursor: pointer;
    display: inline-block;
}

.faq_page span {
    font-size: 18px;
    color: #fff;
    /* word-break: break-word; */
}

.ant-modal-footer button.forgot_text {
    border: 0;
    width: 228px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.forgot_text img.send_new {
    width: 100%;
}

.background_image.space-bottom {
    padding-bottom: 15px;
}

.image_wrapper_style {
    width: 100px;
    height: 100px;
    margin: auto;
    position: relative;
}

img.plus_icon {
    position: absolute;
    bottom: 0;
    left: 1px;
    width: 30px;
}

.no-data-found {
    height: 100%;
    text-align: center;
    color: #2e8f81;
    font-size: 15px;
    /* line-height: 212px; */
    font-weight: 700;
}

.diff_stl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.no_data_found_child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.img_disabled {
    opacity: 0.5;
    cursor: default;
}


.first_para p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

span.button-switch.button-switch_fixed {
    width: 170px;
    margin-left: 7px;

}

p.sure_delete {
    /* padding: 0px 35px 0; */
    padding: 54px 4px 0 !important;
}

.main_pop.pops {
    padding: 0px 39px;
}

.special_case button.button_switch.items {
    position: relative;
    right: 7px;
    top: 0px;
}

span.button-switch.special_case {
    line-height: 25px;
}



/* New add on*/
.first_para h2 {
    color: #2e8f81;
    font-size: 30px;
    font-weight: 500;
}

.wrapper-inner {
    width: 84%;
    margin: 50px auto;
    background-color: #202020;
    padding: 15px 33px;
}

span.highLgt_color {
    color: #2e8f81;
}

.first_para h3 {
    font-size: 20px;
    color: #2e8f81;
    margin-bottom: 0;
}

img.img_p.img3 {
    width: 15%;
}

img.img_p.trages {
    width: 16%;
}

img.img_p.small {
    width: 11%;
}

.info_container {
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 15px 35px 0 0;
}

form#forgot_form {
    padding: 33px 0px;
}

.ant-modal.lobby_modal_reload .ant-modal-close {
    display: none;
}

.lobby_modal_reload .form-group.success_d.leave-pop p {
    font-size: 17px;
}

.lobby_modal_reload .img_y.alert_icons img {
    width: 106px;
}

.container_pawn {
    position: 'absolute';
    z-index: '2';
    object-fit: cover;
}

.gameScreen {
    display: flex;
}

.buy_button {
    background: url(../assets/images/buttonBg.png) no-repeat;
    cursor: pointer;
    border: none;
    background-repeat: round;
    width: 130px;
    height: 40px;
    color: white;
    font-weight: 700;
    /* margin-top: 4px; */
    font-size: 17px;
    margin-bottom: 12px;
}

.data_send {
    cursor: pointer;
}

.ant-message-notice-content>div>span {
    font-weight: 600;
}

.ant-message .anticon {
    display: none !important;
}

.customToastSuccess .ant-message-notice-content {
    background-color: #2E8F81 !important;
}

.customToastError .ant-message-notice-content {
    background-color: red !important;
}